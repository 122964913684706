.nexticon{
    animation: nexticonAnimate .75s infinite alternate ease-out;
}

@keyframes nexticonAnimate {
    from{
        transform: translateX(0);
    }

    to{
        transform : translateX(10px);
    }
}