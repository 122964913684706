@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-width: 100px;
  min-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar
{
    display: none;
}

.mobile-layout {
  max-width: 100%;
  margin: auto;
}

@media (orientation: landscape) and (min-width: 480px) {
  .mobile-layout {
      max-width: 480px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Delicious;
  src: url(font/LexendDeca-VariableFont_wght.ttf);
}

.lexend {
  font-family: Delicious;
  ;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.safebottom
{
  bottom : env(safe-area-inset-bottom);
}